/* eslint-disable @next/next/no-img-element */
import { Button, Input, PasswordInput } from '@mantine/core';
import Head from 'next/head';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import Text from 'andromeda/text';
import { useAppContext } from '../contexts/appContext';
import useColorScheme from '../hooks/useColorScheme';

const commonStyling: React.CSSProperties = {
    textAlign: 'center',
    color: 'var(--navy-solid-50)',
};

const Login = () => {
    const { credentialLogin, googleSiberiaLogin, microsoftSSOSiberiaLogin } = useAppContext();
    const [formError, setFormError] = useState<string>();
    const { colorScheme } = useColorScheme();

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const { username, password } = e.currentTarget as typeof e.currentTarget & {
                username: { value: string };
                password: { value: string };
            };
            const res = await credentialLogin({
                username: username.value,
                password: password.value,
            });
            if (res === 'You do not have permission to perform this action.') {
                setFormError('Invalid Credentials');
                username.value = '';
                password.value = '';
            }
        },
        [credentialLogin],
    );

    const isProximoDomain = window.location.hostname.includes('proximo');
    const isCarlsbergDomain = window.location.hostname.includes('carlsberg');

    return (
        <div className="flex flex-col justify-center items-center h-screen max-w-md mx-auto">
            <Head>
                <title>Arena</title>
                <link rel="shortcut icon" href="/images/favicon.png" />
            </Head>
            <div className="flex flex-row justify-center items-center">
                <img
                    alt="arena"
                    src={`images/arenaLogoFull-${colorScheme}.svg`}
                    style={{
                        height: '42px',
                        marginBottom: '10px',
                        width: 'auto',
                    }}
                />
            </div>
            <div className="flex gap-2 p-3">
                {!isProximoDomain && (
                    <Button
                        className="!bg-transparent hover:!bg-primary-10 !text-primary-90 !border-gray-600"
                        type="button"
                        variant="default"
                        data-testid="login-google"
                        onClick={googleSiberiaLogin}
                    >
                        Spaceport Google Login
                    </Button>
                )}
                <Button
                    className="!bg-transparent hover:!bg-primary-10 !text-primary-90 !border-gray-600"
                    type="button"
                    variant="default"
                    data-testid="login-microsoft"
                    onClick={microsoftSSOSiberiaLogin}
                >
                    {isProximoDomain || isCarlsbergDomain ? 'OneBrain' : 'Spaceport'} Microsoft
                    Login
                </Button>
            </div>
            {!isProximoDomain && (
                <div className="p-3 w-full pb-0 border-t-1 border-gray-600">
                    <form
                        onSubmit={handleSubmit}
                        className="min-w-[275px] w-full flex flex-col gap-2"
                    >
                        <Input
                            type="text"
                            name="username"
                            id="username"
                            placeholder="Username"
                            classNames={{
                                input: '!border-gray-600 !text-primary placeholder:!text-navy-solid-10 !bg-transparent !text-center',
                            }}
                            styles={{
                                input: {
                                    paddingRight: '33px',
                                },
                            }}
                        />
                        <PasswordInput
                            name="password"
                            placeholder="Password"
                            id="password"
                            classNames={{
                                input: '!border-gray-600 !text-primary placeholder:!text-navy-solid-10 !bg-transparent !text-center',
                                innerInput:
                                    '!text-primary placeholder:!text-navy-solid-10 !text-center',
                                root: '!text-center',
                            }}
                        />
                        <Button
                            className="!bg-transparent hover:!bg-primary-10 !text-primary-90 !border-gray-600"
                            type="submit"
                            variant="default"
                        >
                            Login
                        </Button>
                        <Text
                            type="Button/regular"
                            className={classNames('text-red-600 text-center', {
                                hidden: !formError,
                            })}
                        >
                            {formError}
                        </Text>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Login;
