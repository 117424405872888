import {
    ChartLine,
    Flask,
    Graph,
    Layout,
    ListChecks,
    MagnifyingGlass,
    Megaphone,
    Path,
    Storefront,
    Tag,
    TrendUp,
    UserList,
    Wrench,
} from 'phosphor-react';
import { Microscope } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { UserGroup } from '@/types/user';
import { NavItem } from './constants';

const MOST_USED_ABI_USERS: UserGroup[] = [
    'spaceport-EC-NESTLE-access',
    'spaceport-PH-NESTLE-access',
    'spaceport-ID-NESTLE-access',
    'spaceport-PA-access',
    'spaceport-BE-access',
    'spaceport-NL-access',
    'spaceport-AR-access',
    'spaceport-BO-access',
    'spaceport-CA-access',
    'spaceport-DE-access',
    'spaceport-KR-access',
    'spaceport-PY-access',
    'spaceport-ES-access',
    'spaceport-UK-access',
    'spaceport-UY-access',
    'spaceport-EC-ABI-access',
    'spaceport-ZA-access',
    'spaceport-GB-access',
    'spaceport-UG-access',
    'spaceport-TZ-access',
    'spaceport-BR-access',
    'spaceport-MX-access',
    'spaceport-CO-access',
    'spaceport-HN-access',
    'spaceport-SV-access',
    'spaceport-PE-access',
    'spaceport-DO-access',
];
// ABI_USERS;
export const useNavItems = (): NavItem[] =>
    useMemo(
        () => [
            {
                href: 'console',
                children: <Path size={20} />,
                userGroupWhitelist: [
                    'spaceport-EC-NESTLE-access',
                    'spaceport-PH-NESTLE-access',
                    'spaceport-ID-NESTLE-access',
                    'spaceport-PA-access',
                    'spaceport-BE-access',
                    'spaceport-NL-access',
                    'spaceport-AR-access',
                    'spaceport-BO-access',
                    'spaceport-CA-access',
                    'spaceport-DE-access',
                    'spaceport-KR-access',
                    'spaceport-PY-access',
                    'spaceport-ES-access',
                    'spaceport-UK-access',
                    'spaceport-UY-access',
                    'spaceport-EC-ABI-access',
                    'spaceport-ZA-access',
                    'spaceport-GB-access',
                    'spaceport-UG-access',
                    'spaceport-TZ-access',
                    'spaceport-BR-access',
                    'spaceport-MX-access',
                    'spaceport-CO-access',
                    'spaceport-HN-access',
                    'spaceport-SV-access',
                    'spaceport-PE-access',
                    'spaceport-DO-access',
                ],
            },
            {
                href: 'insights',
                children: <ChartLine size={20} />,
                userGroupWhitelist: MOST_USED_ABI_USERS,
                sharableKeys: ['usecase', 'namespace'],
            },
            {
                href: 'experiments',
                children: <Flask size={20} />,
                userGroupWhitelist: [
                    'spaceport-EC-NESTLE-access',
                    'spaceport-PH-NESTLE-access',
                    'spaceport-ID-NESTLE-access',
                    'spaceport-PA-access',
                    'spaceport-BE-access',
                    'spaceport-NL-access',
                    'spaceport-AR-access',
                    'spaceport-BO-access',
                    'spaceport-CA-access',
                    'spaceport-DE-access',
                    'spaceport-KR-access',
                    'spaceport-PY-access',
                    'spaceport-ES-access',
                    'spaceport-UK-access',
                    'spaceport-UY-access',
                    'spaceport-EC-ABI-access',
                    'spaceport-ZA-access',
                    'spaceport-GB-access',
                    'spaceport-UG-access',
                    'spaceport-TZ-access',
                    'spaceport-BR-access',
                    'spaceport-MX-access',
                    'spaceport-CO-access',
                    'spaceport-HN-access',
                    'spaceport-SV-access',
                    'spaceport-PE-access',
                    'spaceport-DO-access',
                ],
                sharableKeys: ['usecase', 'namespace'],
            },
            {
                href: 'personalizations',
                userGroupWhitelist: [
                    'spaceport-US-ABONE-NY-access',
                    'spaceport-US-DEMO-access',
                    'spaceport-US-WILSBACH-access',
                    'spaceport-EC-NESTLE-access',
                    'spaceport-PH-NESTLE-access',
                    'spaceport-ID-NESTLE-access',
                    'spaceport-BE-access',
                    'spaceport-PA-access',
                    'spaceport-NL-access',
                    'spaceport-AR-access',
                    'spaceport-BO-access',
                    'spaceport-CA-access',
                    'spaceport-DE-access',
                    'spaceport-KR-access',
                    'spaceport-PY-access',
                    'spaceport-ES-access',
                    'spaceport-UK-access',
                    'spaceport-UY-access',
                    'spaceport-ZA-access',
                    'spaceport-EC-ABI-access',
                    'spaceport-GB-access',
                    'spaceport-UG-access',
                    'spaceport-TZ-access',
                    'spaceport-BR-access',
                    'spaceport-MX-access',
                    'spaceport-CO-access',
                    'spaceport-HN-access',
                    'spaceport-SV-access',
                    'spaceport-PE-access',
                    'spaceport-DO-access',
                ],
                children: <UserList size={20} />,
                sharableKeys: ['usecase', 'namespace'],
            },
            {
                href: 'configurations',
                userGroupWhitelist: [
                    'spaceport-US-ABONE-NY-access',
                    'spaceport-US-DEMO-access',
                    'spaceport-US-WILSBACH-access',
                    'spaceport-EC-NESTLE-access',
                    'spaceport-ID-NESTLE-access',
                    'spaceport-PH-NESTLE-access',
                    'spaceport-BE-access',
                    'spaceport-PA-access',
                    'spaceport-NL-access',
                    'spaceport-AR-access',
                    'spaceport-BO-access',
                    'spaceport-CA-access',
                    'spaceport-DE-access',
                    'spaceport-KR-access',
                    'spaceport-PY-access',
                    'spaceport-ES-access',
                    'spaceport-UK-access',
                    'spaceport-UY-access',
                    'spaceport-ZA-access',
                    'spaceport-EC-ABI-access',
                    'spaceport-GB-access',
                    'spaceport-UG-access',
                    'spaceport-TZ-access',
                    'spaceport-MX-access',
                    'spaceport-CO-access',
                    'spaceport-HN-access',
                    'spaceport-SV-access',
                    'spaceport-PE-access',
                    'spaceport-DO-access',
                ],
                children: <Wrench size={20} />,
                sharableKeys: ['usecase', 'namespace'],
                routerQueryKeys: ['usecase', 'namespace'],
            },
            {
                href: 'search',
                children: <MagnifyingGlass size={20} />,
                userGroupWhitelist: ['bausch-users'],
            },
            {
                href: 'hermes',
                children: <ListChecks size={20} />,
                userGroupWhitelist: ['bausch-users', 'demo-users'],
            },
            {
                href: 'ontology',
                children: <Graph size={20} />,
                userGroupWhitelist: ['demo-users'],
            },
            {
                href: 'pricing',
                children: <Tag size={20} />,
                userGroupWhitelist: ['proximo-users'],
            },
            {
                href: 'stores',
                children: <Storefront size={20} />,
                userGroupWhitelist: ['proximo-users'],
            },
            {
                href: 'market-performance',
                children: <TrendUp size={20} />,
                userGroupWhitelist: ['proximo-users'],
            },
            {
                href: 'product-analysis',
                children: <Microscope size={20} />,
                userGroupWhitelist: ['proximo-users'],
            },
            {
                href: 'promos',
                children: <Megaphone size={20} />,
                userGroupWhitelist: [
                    'spaceport-PH-NESTLE-access',
                    'spaceport-BR-access',
                    'abi-promos-spaceport',
                ],
            },
            {
                href: 'catalog',
                children: <Layout size={20} />,
                userGroupWhitelist: MOST_USED_ABI_USERS,
            },
        ],
        [],
    );
