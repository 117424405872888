const IS_RELEASE = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF?.startsWith?.('release');

export const IS_PROD = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' || IS_RELEASE;

export const API_URL = `https://fusion-${IS_PROD ? 'prod' : 'dev'}.internal.arena-ai.com`;
export const HEAP_ID = process.env.NEXT_PUBLIC_HEAP_ID;
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const SPACEPORT_API_URL = `https://spaceport-api-${IS_PROD ? 'prod' : 'uat'}.arena-ai.com`;
export const PROMO_API_URL = `https://spaceport-api-phobos${
    IS_PROD ? '-prod' : '-uat'
}.arena-ai.com`;

// export const AUTH_API_HOST = `https://dexter${IS_PROD ? '' : '-dev'}.arena-ai.com`;
// TODO : disabling dexter for the callisto demo, will need to update with process env variable later
export const AUTH_API_HOST = `https://callisto-api-uat.arena-ai.com`;
export const CALLISTO_API = `https://callisto-api-uat.arena-ai.com`;

export const PROMOS_API_URL = `https://dexter${IS_PROD ? '' : '-dev'}.arena-ai.com`;
export const PHOBOS_API_URL = 'https://spaceport-phobos-prod.arena-ai.com';

export const FUSION_API_URL = `https://fusion-${IS_PROD ? 'prod' : 'dev'}.internal.arena-ai.com`;

export const SPACEPORT_UAT_REC_IMG =
    'https://beesuatfilemgmt.blob.core.windows.net/files-ec/global-recommender_mensaje-generico-producto.jpg?sig=aWTMsM761Bfau8DRZIBJEGVxCZZ1WShWb2LnTCL9b3A%3D&se=3022-06-15T11%3A34%3A46Z&sv=2015-04-05&sp=r&sr=b';

export const SPACEPORT_PROD_REC_IMG =
    'https://beesfilemgmt.blob.core.windows.net/files-ec/global-recommender_mensaje-generico-producto.png?sig=clpeBUAapVbuCWqaoAuNwVlEu5pC49%2BCqR%2B8O2hkuYQ%3D&se=3022-06-20T22%3A15%3A06Z&sv=2015-04-05&sp=r&sr=b';

export const IRIS_URL = `https://spaceport-api-iris-${IS_PROD ? 'prod' : 'uat'}.arena-ai.com`;
export const MAPBOX_ACCESS_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;
