import type { AppProps } from 'next/app';
import { QueryClientProvider } from '@tanstack/react-query';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import isMobile from 'ismobilejs';
import Head from 'next/head';
import React, { useEffect, useRef } from 'react';
import { hotjar } from 'react-hotjar';
import '../styles/tailwind.css';
import 'regenerator-runtime/runtime';

import { I18nextProvider } from 'react-i18next';
import { useLocalStorage } from '@mantine/hooks';
import RenderGlobalCSS from '@/components/utils/renderGlobalCSS';
import { ColorScheme } from '@/types/general';
import { GlobalContextProvider } from '@/contexts/GlobalContext';
import { HOTJAR_ID } from 'buildConfig/processEnv';
import AppContextProvider, { useAppContext } from '../contexts/appContext';
import i18n from '../i18n';
import { queryClient } from '../utils/reactQueryConfig';
import MobileHomePage from './mobile-home';

export default function App({ Component, pageProps }: AppProps) {
    const [colorScheme] = useLocalStorage<ColorScheme>({
        key: 'color-scheme',
        defaultValue: 'light',
    });

    useEffect(() => {
        const id = Number(process.env.NEXT_PUBLIC_HOTJAR_ID) || 0;
        hotjar.initialize({ id, sv: 6 });
    }, []);
    const mobile = useRef(isMobile().phone);
    const { user } = useAppContext();

    // Use the layout defined at the page level, if available
    const getLayout = (Component as any).getLayout || (page => page);

    return (
        <I18nextProvider i18n={i18n}>
            <ColorSchemeScript defaultColorScheme={colorScheme} />
            <MantineProvider
                defaultColorScheme={colorScheme}
                theme={{
                    fontFamily: 'inherit',
                    headings: {
                        fontFamily: 'inherit',
                        fontWeight: '500',
                        sizes: {
                            h1: { fontSize: '28px' },
                            h2: { fontSize: '22px' },
                            h3: { fontSize: '18px' },
                            h4: { fontSize: '16px' },
                            h5: { fontSize: '14px' },
                            h6: { fontSize: '12px', lineHeight: '1rem' },
                        },
                    },
                    components: {
                        Modal: {
                            classNames: {
                                overlay: 'z-[500]',
                                inner: 'z-[501]',
                            },
                        },
                        Input: {
                            classNames: {
                                input: '!bg-transparent aria-[invalid=false]:border-border-color aria-[invalid=undefined]:border-border-color aria-[invalid=true]:border-red',
                            },
                        },
                        Checkbox: {
                            classNames: {
                                input: '!border-navy-solid-50 !text-navy-solid-50  disabled:!bg-navy-solid-10 !bg-transparent',
                            },
                            defaultProps: {
                                iconColor: 'var(--navy-solid-50)',
                            },
                        },
                    },
                }}
                withCssVariables
            >
                <RenderGlobalCSS />
                <Notifications />
                <Head>
                    {/* Disabling auto zoom in input for Safari on iPhone and setting a smaller initial scale */}
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=0.85, maximum-scale=0.85"
                    />
                </Head>

                <QueryClientProvider client={queryClient}>
                    <AppContextProvider>
                        {mobile.current && user.token && !user?.siberiaToken ? (
                            <MobileHomePage />
                        ) : (
                            <GlobalContextProvider>
                                {getLayout(<Component {...pageProps} />)}
                            </GlobalContextProvider>
                        )}
                    </AppContextProvider>
                    <div className="relative z-[99999999999999]">
                        <ReactQueryDevtools initialIsOpen={false} />
                    </div>
                </QueryClientProvider>
            </MantineProvider>
        </I18nextProvider>
    );
}
