import { useRouter } from 'next/router';
import Layout from '../components/layout';
import MobileHome from '../components/mobileHome';
import MarketRecap from './market-recap';

const MobileHomePage = () => {
    const router = useRouter();

    return (
        <Layout
            title="Home"
            className="!px-0 pt-14 h-screen overflow-hidden"
            style={{
                backgroundImage: 'url(/images/mobile-home/mobile-home.png)',
                backgroundSize: 'cover',
            }}
        >
            {router.pathname === '/depletion' ? <MobileHome /> : <MarketRecap />}
        </Layout>
    );
};

export default MobileHomePage;
