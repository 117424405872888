import React from 'react';
import { AppShell, Image } from '@mantine/core';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { Gear, Question } from 'phosphor-react';
import { ActiveLink } from './ActiveLink';

import { useGlobalContext } from '../../contexts/GlobalContext';
import { ArenaTextLogo } from '../utils/ArenaTextLogo';
import { useNavItems } from './useNavItems';

const NavItemsWrapper = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) => <div className={cx('flex flex-col min-w-[53.63px]', className)}>{children}</div>;

function NavBar() {
    const router = useRouter();
    const navbarIsHovering = useGlobalContext(state => state.navbarIsHovering);
    const updateIsNavbarHovering = useGlobalContext(state => state.updateIsNavbarHovering);

    const pages = useNavItems();

    return (
        <AppShell>
            <AppShell.Navbar
                className={cx(
                    'flex flex-col !w-20 gap-6 items-center py-5 pl-5 glass-effect shadow-lg relative z-[400]',
                    { '!w-fit': navbarIsHovering },
                )}
                unstyled
                onMouseEnter={() => updateIsNavbarHovering(true)}
                onMouseLeave={() => updateIsNavbarHovering(false)}
            >
                <ActiveLink className="flex items-center gap-1" href="/" isLogo>
                    <Image
                        className="!w-[45.7px] !h-[45px]"
                        src="/images/arenaLogo.svg"
                        height={45}
                        alt="logo"
                        onClick={() => {
                            router.push('/');
                            updateIsNavbarHovering(false);
                        }}
                    />
                    {navbarIsHovering && <ArenaTextLogo className="fill-primary-70" size={114} />}
                </ActiveLink>
                <NavItemsWrapper className={cx('justify-between flex-grow pt-4 w-full')}>
                    <NavItemsWrapper className="flex-grow max-h-[65vh] overflow-auto gap-4">
                        {pages.map(item => (
                            <ActiveLink isHovering={navbarIsHovering} key={item.href} {...item} />
                        ))}
                    </NavItemsWrapper>
                    <NavItemsWrapper className="border-t border-t-gray-600 pl-5 -ml-5 pt-4 gap-4">
                        <ActiveLink isHovering={navbarIsHovering} href="spaceport-settings">
                            <Gear size={20} />
                        </ActiveLink>
                    </NavItemsWrapper>
                </NavItemsWrapper>
                {/* {isBauschUser && (
                    <FullPageChat opened={visible} onClose={() => setVisible(false)} />
                )} */}
            </AppShell.Navbar>
        </AppShell>
    );
}

export default React.memo(NavBar);
