import { UserGroup } from '../../types/user';

export const OneBrainURL = 'https://docs.arena-ai.com/fusion';

export type BennuPagesType =
    | 'market-recap'
    | 'sales-assistant'
    | 'market-overview'
    | 'banner-assistant'
    | 'assortment-optimization';

export type ABIPagesType = 'pricing-intelligence';

export type ArenaPagesType = 'entity-resolution' | 'developer' | 'user' | 'demo';
export type PromosPagesType = 'promos' | 'pricing-intelligence';

export const SPACEPORT_PAGES = [
    '',
    'console',
    'personalizations',
    'spaceportHome',
    'configurations',
    'strategy',
    'datasets',
    'promos',
    'wiki',
    'experiments',
] as const;

export type SpaceportPagesType = (typeof SPACEPORT_PAGES)[number];

export type AllPagesType =
    | '/'
    | 'consumers'
    | 'growth-actions'
    | 'integrations'
    | 'intelligence'
    | 'productSearch'
    | 'settings'
    | 'spaceport-settings'
    | 'market-performance'
    | 'context-module'
    | 'apis'
    | 'user'
    | 'adjustments'
    | 'https://docs.arena-ai.com/fusion'
    | 'product'
    | 'ontology'
    | 'hermes'
    | 'search'
    | 'pricing'
    | 'stores'
    | 'catalog'
    | BennuPagesType
    | ArenaPagesType
    | ABIPagesType
    | SpaceportPagesType
    | PromosPagesType
    | 'insights'
    | 'product-analysis';

export type PagesType = AllPagesType;

export const PAGE_NAMES: Record<AllPagesType, string> = {
    '/': 'Home',
    '': 'Home',
    consumers: 'Consumers',
    'growth-actions': 'Growth Actions',
    integrations: 'Integrations',
    intelligence: 'Intelligence',
    productSearch: 'Products',
    settings: 'Settings',
    'spaceport-settings': 'Settings',
    'banner-assistant': 'Off-Trade Assortment',
    developer: 'Developer for Arena Staff',
    'market-recap': 'Nielsen Market Recap',
    'sales-assistant': 'On-Trade Assortment',
    'market-overview': 'Market Deep Dive',
    'pricing-intelligence': 'Pricing',
    'entity-resolution': 'Data Stitching & ER',
    'assortment-optimization': 'Assortment Optimization',
    'market-performance': 'Market Performance',
    'context-module': 'Store Context',
    apis: 'APIs & Documentation',
    console: 'Console',
    personalizations: 'Personalizations',
    spaceportHome: 'Home',
    catalog: 'Catalog',
    configurations: 'Configurations',
    strategy: 'Strategy',
    adjustments: 'Adjustments',
    datasets: 'Datasets',
    promos: 'Promo Performance',
    wiki: 'Wiki',
    'https://docs.arena-ai.com/fusion': 'OneBrain API',
    product: 'Product',
    ontology: 'Ontology',
    hermes: 'Hermes',
    user: 'User',
    demo: 'Demo',
    search: 'Search',
    pricing: 'Pricing Intelligence',
    stores: 'Athena: Store Intelligence',
    'product-analysis': 'Product Analysis',
    experiments: 'Experiments',
    insights: 'Insights',
};

export const TBD_PAGE_NAMES = {
    ontology: 'Ontology',
    hermes: 'Pipeline',
    user: 'User',
    demo: 'Demo',
    search: 'Search',
    '/': 'Home',
};
export interface NavItem {
    href: PagesType;
    children: React.ReactNode;
    className?: string;
    routerQueryKeys?: string[];
    sharableKeys?: string[];
    shareRoutesQueryKeysWith?: Array<PagesType>;
    userGroupWhitelist?: UserGroup[];
    userGroupBlacklist?: UserGroup[];
    isHovering?: boolean;
    customLabel?: string;
    tooltipLabel?: string;
    disabled?: boolean;
    ruleOverRideWithOriginalGroup?: boolean;
    isLogo?: boolean;
    overrideAccess?: boolean;
}

export const ARENA_DEV_ROUTES = ['user', 'demo', 'developer'];
